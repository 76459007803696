




















import { Component, Vue } from "vue-property-decorator";
import HelloWorld from "./components/HelloWorld.vue";

@Component({
  components: {
    HelloWorld,
  },
})
export default class App extends Vue {
  listKategorii = [
    { jmeno: "Kliky", pocet: 0, mnozstviPridat: 20},
    { jmeno: "Shyby", pocet: 0 },
    { jmeno: "klikyhaky", pocet: 0 },
  ];

  inputNazev = ""
  inputMnozstvi = 1

  pridatRep(cisloKategorie: number) {
    let zvolenaKat = this.listKategorii[cisloKategorie]
    zvolenaKat.pocet += zvolenaKat.mnozstviPridat ?? 1
  }
  ulozitKategorii() {
    if(this.inputNazev === "") return alert("Jses debil!")
    this.listKategorii.push({jmeno: this.inputNazev, pocet: 0, mnozstviPridat: Number(this.inputMnozstvi)})
    this.inputNazev = ""
    this.inputMnozstvi = 1
  }
}
